/* index.css */

/* Estilos globales */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Arial', sans-serif;
}

/* Fondo de la página (tema oscuro) */
body {
  background-color: #1f1f1f; /* Gris oscuro */
  background: url('../public/bg_bn.jpg') center/cover no-repeat;
  background-position: fixed center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  user-select: none;
}

/* Contenedor principal del formulario */
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 1rem; /* Añadido para dar margen en dispositivos pequeños */
}

/* Formulario de login */
.login-form {
  background-color: #00000000; /* Fondo gris oscuro */
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 0 50px #000;
  width: 100%;
  max-width: 500px;
  text-align: center;
  opacity: 0; /* Inicialmente oculto para animación */
  animation: fadeIn 0.8s ease forwards; /* Aplicar la animación */
  backdrop-filter: blur(20px);
}

/* Logo */
.logo-container {
  margin-bottom: 1rem;
}

.logo {
  width: 100%;
  margin: 0 0 20px 0;
  animation: fadeIn 0.8s ease forwards; /* Animación para logo */
}

.login-title {
  font-size: 1.5rem;
  color: #3fb549; /* Verde biche */
  margin-bottom: 1.5rem;
  animation: fadeIn 0.8s ease forwards;
}

/* Estilos para los inputs */
/* Contenedor para el input con icono */
/* Contenedor para el input con icono */
.input-icon-group {
  position: relative;
  margin-bottom: 1.2rem;
}

.input-icon {
  position: absolute;
  top: 50%;
  left: 10px; /* Ajusta la posición del icono */
  transform: translateY(-50%);
  color: #888; /* Color del icono */
  font-size: 1.2rem;
  pointer-events: none;
}

.input-field {
  width: 100%;
    padding: 0.8rem;
    padding-left: 2.5rem;
    color: #ffffff;
    font-size: 1rem;
    border: none;
    outline: none;
    background: transparent;
    box-shadow: none;
    border-bottom: solid 2px #3fb549;
}

.input-field:focus {
  outline: none;
}


/* Botón de submit */
.submit-button {
  background-color: #27802e; /* Verde biche */
  color: #ffffff;
  border: none;
  padding: 0.8rem;
  border-radius: 4px;
  width: 100%;
  font-size: 1rem;
  cursor: pointer;
  opacity: 0;
  animation: fadeIn 0.8s ease forwards;
  margin-top: 2rem;
  transition: background-color 0.3s, /* Una transición combinada */
}

.submit-button:hover {
  background-color: #3fb549; /* Verde más oscuro */
  transform: scale(1.2); /* Zoom */
}

.submit-button:focus {
  outline: none;
  box-shadow: 0 0 5px rgba(72, 187, 120, 0.8);
}

.disabled-button {
  background-color: #ccc;
  color: #666;
  cursor: not-allowed
}
.disabled-button:hover {
  background-color: #ccc;
  color: #666;
  cursor: not-allowed
}
/* Mensaje de error */
.error-message {
  color: #e53e3e; /* Rojo */
  margin-top: 1rem;
  font-size: 0.875rem;
  opacity: 0;
  animation: fadeIn 0.8s ease forwards;
}

/* Enlace de "Olvidaste tu contraseña" */
.forgot-password {
  margin-top: 1rem;
}

.forgot-password-link {
  color: #3fb549;
  font-size: 0.875rem;
  text-decoration: none;
}

.forgot-password-link:hover {
  text-decoration: underline;
}

/* Animación Fade In */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px); /* Animación desde abajo */
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Ocultar inicialmente los elementos */
.fade-in {
  opacity: 0;
  animation: fadeIn 0.8s ease forwards;
}

/* Añadir retrasos para cada elemento */
.fade-in-1 {
  animation-delay: 0.2s;
}
.fade-in-2 {
  animation-delay: 0.4s;
}
.fade-in-3 {
  animation-delay: 0.6s;
}
.fade-in-4 {
  animation-delay: 0.8s;
}
